@use "@uilab/scss/typography" as uilab-typography;
@use "@uilab/scss/theme" as uilab-theme;
@use "@uilab/scss/layout/mixin" as uilab-layout-mixins;
@use "@uilab/scss/theme/neutral" as uilab-neutral;
@use "@uilab/scss/layout/layout";

@import "@aveva/connect-web-core/aveva.themes";
@import "@uilab/core-angular/scss/material";

.mat-toolbar {
  z-index: 2;
  box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%);
}

html,
body {
  height: 100%;
  @include uilab-typography.typography-body-1;
  @include uilab-theme.background-solid;
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  cursor: default;
}

app-root {
  @include uilab-layout-mixins.parent-container;
}